import React, { FC } from "react";
import { LocalBusinessJsonLd } from "gatsby-plugin-next-seo";
import gippio from "../assets/dashboard/gippio.gif";
import gippiol from "../assets/dashboard/profilepic.jpg";
import { Link, graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Img from "gatsby-image";

const Booking: FC = ({ data }: any) => {
  return (
    <React.Fragment>
      <LocalBusinessJsonLd
        type="Store"
        id="http://casafraine.com"
        name="Casa Fraine"
        description="Prodotti che supportano la comunità"
        url="http://www.casafraine.com/"
        telephone="+3200834000"
        address={{
          streetAddress: "Via Santa Maria",
          addressLocality: "Fraine",
          addressRegion: "CH",
          postalCode: "66050",
          addressCountry: "IT",
        }}
        geo={{
          latitude: "14.4882245",
          longitude: "41.9056384",
        }}
        images={[
          "https://casafraine.com/formaggiodicapra.jpg",
          "https://casafraine.com/mapitaly.png",
          "https://casafraine.com/olioextraverginedioliva.jpg",
        ]}
      />

      <GatsbySeo
        title="Casa Fraine | Booking"
        description="Accetto volontari/viaggiatori che vogliono fermarsi a lavorare da me per un po` in cambio di vitto e alloggio"
        canonical="https://www.casafraine.com/booking"
        openGraph={{
          url: "https://www.casafraine.com/booking",
          title: "Casa Fraine | Booking",
          description:
            "Accetto volontari/viaggiatori che vogliono fermarsi a lavorare da me per un po` in cambio di vitto e alloggio",

          images: [
            {
              url: `${data.allFile.nodes[0].childImageSharp.fluid.src}`,
              width: 800,
              height: 600,
              alt: "Booking",
            },
            {
              url: `${data.allFile.nodes[0].childImageSharp.fluid.src}`,
              width: 900,
              height: 800,
              alt: "Booking",
            },
            { url: `${data.allFile.nodes[0].childImageSharp.fluid.tracedSVG}` },
            { url: `${data.allFile.nodes[0].childImageSharp.fluid.tracedSVG}` },
          ],
          site_name: "Casa Fraine",
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />

      <section className=" home hero">
        <h2>
          Come&nbsp;
          <span>Help</span>
        </h2>
        <Img
          className="img img-section"
          fluid={data.allFile.nodes[0].childImageSharp.fluid}
          alt="work away"
        />
      </section>
      <section className=" booking calendar">
     
        <center>
          <img src={gippio} alt="man at work" />
          <img src="https://scontent.fcia7-1.fna.fbcdn.net/v/t39.30808-6/281160195_540282534475988_2605572042272531493_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=9fPiBe6bmDoAX8kNa5e&_nc_ht=scontent.fcia7-1.fna&oh=00_AT-Dd8Rp-wEJ5q1jYyVqDhWxBlBoAbD7Ny4Dp7EpGmAiqQ&oe=6293BE35" alt="Vincenzo Marcovecchio" />
          <h1>Casa Fraine</h1>
          <h2>Non trovo nomi interessanti per questo sito quindi lo chiamerò semplicemente CasaFraine anche perchè questo è tutto quello che ho ed è bene che io riparti da 0</h2>
          <p>
            psss..! vuoi aiutarmi a rimanere a Fraine, lasciami un pezzo di
            ferro, una trave, oppure qui&nbsp;🚀{" "}
            <code>0x4E702f72BA51318545B3FA79a96Ec833CBd83fD0</code> 🚀
          </p>


        </center>
        <iframe
          loading="lazy"
          src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;src=ZW4uaXJpc2gjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%237986CB&amp;color=%237986CB&amp;title=Stay%20in%20Fraine&amp;showTabs=1&amp;showCalendars=0"
          width="800"
          height="600"
          frameBorder="0"
          scrolling="no"
          style={{
            margin: "0.5rem auto",
            textAlign: "center",
            display: "flex",
          }}
        ></iframe>

        <a class="twitter-timeline" href="https://twitter.com/_vinny_92?ref_src=twsrc%5Etfw">Tweets by _vinny_92</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
      </section>
    </React.Fragment>
  );
};

export const query = graphql`
  query bookingQuery {
    allFile(
      filter: { sourceInstanceName: { eq: "images" }, name: { eq: "booking" } }
      sort: { fields: base }
    ) {
      nodes {
        name
        absolutePath
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
export default Booking;
